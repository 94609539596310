import React, { useState, useRef, useEffect } from 'react'
import FormCustomInput from './FormCustomInput'
import FormCustomTextarea from './FormCustomTextarea'
import {
	Button,
	Image,
	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalBody,
	ModalCloseButton,
	CircularProgress,
	useDisclosure,
	CircularProgressLabel,
} from '@chakra-ui/react'
import { useStore } from '../../../store/store'
import { useShallow } from 'zustand/react/shallow'
import { X } from 'lucide-react'

function ContactForm() {
	const {
		convos,
		email,
		setEmail,
		formName,
		setFormName,
		textDescription,
		setTextDescription,
		uploadedFiles,
		setUploadedFiles,
		handleCustomerSupportForm,
		handleCustomerSupportFormPayload,
		formChatHistory,
		formSubmitted,
		setFormSubmitted,
		setContactSupportForm,
		updateConvo,
	} = useStore(
		useShallow((state) => ({
			convos: state.convos,
			email: state.email,
			setEmail: state.setEmail,
			formName: state.formName,
			setFormName: state.setFormName,
			textDescription: state.textDescription,
			setTextDescription: state.setTextDescription,
			uploadedFiles: state.uploadedFiles,
			setUploadedFiles: state.setUploadedFiles,
			handleCustomerSupportForm: state.handleCustomerSupportForm,
			handleCustomerSupportFormPayload: state.handleCustomerSupportFormPayload,
			formChatHistory: state.formChatHistory,
			formSubmitted: state.formSubmitted,
			setFormSubmitted: state.setFormSubmitted,
			setContactSupportForm: state.setContactSupportForm,
			updateConvo: state.updateConvo,
		})),
	)

	const [textareaFocus, setTextareaFocus] = useState(false)
	const [submitCheck, setSubmitCheck] = useState(true)
	const [isEmailError, setIsEmailError] = useState(false)
	const [progress, setProgress] = useState(0)
	const textareaWrapperRef = useRef(null)
	const fileInputRef = useRef(null)
	const emailInputRef = useRef(null)
	const { isOpen, onOpen, onClose } = useDisclosure()

	useEffect(() => {
		if (convos.length > 0) {
			handleCustomerSupportFormPayload()
		}
	}, [convos])

	useEffect(() => {
		if (email && textDescription) {
			setSubmitCheck(false)
		} else {
			setSubmitCheck(true)
		}
	}, [email, textDescription])

	useEffect(() => {
		if (formSubmitted) {
			onOpen()
			setProgress(0)
			const timer = setInterval(() => {
				setProgress((prev) => {
					if (prev >= 100) {
						clearInterval(timer)
						handleModalClose()
						return 100
					}
					return prev + 2
				})
			}, 100)
		}
	}, [formSubmitted, onOpen])

	const handleClickOutside = (event) => {
		if (textareaWrapperRef.current && !textareaWrapperRef.current.contains(event.target)) {
			setTextareaFocus(false)
		}
	}

	useEffect(() => {
		document.addEventListener('mousedown', handleClickOutside)
		return () => {
			document.removeEventListener('mousedown', handleClickOutside)
		}
	}, [])

	const handleTextareaClick = () => {
		setTextareaFocus(true)
	}

	const handleUploadClick = () => {
		fileInputRef.current.click()
	}

	const handleFileChange = (event) => {
		const file = event.target.files[0]
		if (file) {
			const reader = new FileReader()
			const newFiles = Array.isArray(uploadedFiles) ? uploadedFiles : []
			reader.onloadend = () => {
				const base64String = reader.result.split(',')[1]
				setUploadedFiles([...newFiles, { id: Date.now(), file_name: file.name, base_64: base64String }])
			}
			reader.readAsDataURL(file)
		}
	}

	const handleRemoveFile = (id) => {
		const filteredFiles = uploadedFiles.filter((file) => file.id !== id)
		setUploadedFiles(filteredFiles)
	}

	const handleContactForm = async (e) => {
		if (e) {
			e.preventDefault()
		}
		const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
		if (!emailRegex.test(email)) {
			setIsEmailError(true)
			return
		} else {
			setIsEmailError(false)
			// handle form submission
			const payload = {
				email,
				name: formName,
				description: textDescription,
				chat_history: formChatHistory,
			}

			handleCustomerSupportForm(payload)
		}
	}

	const getCurrentLocalTime = () => {
		const now = new Date()
		let hours = now.getHours()
		const minutes = now.getMinutes()
		const ampm = hours >= 12 ? 'PM' : 'AM'
		hours = hours % 12
		hours = hours ? hours : 12
		const strTime = hours + ':' + (minutes < 10 ? '0' + minutes : minutes) + ampm
		return strTime
	}

	const handleModalClose = () => {
		onClose()
		setFormSubmitted(false)
		setContactSupportForm(false)

		// add message to convo
		const requestId = convos[0].requestId
		const currentLocalTime = getCurrentLocalTime()
		const customerSupportForm = `Customer support form submitted at ${currentLocalTime}`
		updateConvo({ requestId, customerSupportForm })
	}

	// Focus the email input on initial load
	useEffect(() => {
		if (emailInputRef.current) {
			emailInputRef.current.focus()
		}
	}, [])

	return (
		<div className="max-w-md mx-auto px-[30px] text-white text-[13px] font-medium">
			<h2 className="text-[rgba(250,248,248,0.75)] mb-9 text-[13px] font-normal leading-5">
				Thanks for contacting us. We just need a bit more information from you. Please fill out the following and we'll
				get back to you shortly.
			</h2>
			<form
				className="flex flex-col gap-9 justify-start items-start overflow-y-auto flex-grow relative"
				style={{height: 'calc(100vh - 270px'}}
				onSubmit={handleContactForm}
			>
				<div className="w-full">
					<label htmlFor="email" className="block text-[#FAF8F8] text-[13px] font-medium leading-5 mb-[15px] h-auto">
						Email (required)
					</label>
					<FormCustomInput
						ref={emailInputRef}
						value={email}
						onChange={(e) => setEmail(e.target.value)}
						isError={isEmailError}
						errorMessage="Please enter a valid email"
					/>
				</div>
				<div className="w-full">
					<label htmlFor="name" className="block text-[#FAF8F8] text-[13px] font-medium leading-5 mb-[15px] h-auto">
						Name
					</label>
					<FormCustomInput value={formName} onChange={(e) => setFormName(e.target.value)} />
				</div>
				<div className="w-full" ref={textareaWrapperRef} onClick={handleTextareaClick}>
					<label
						htmlFor="description"
						className="block text-[#FAF8F8] text-[13px] font-medium leading-5 mb-[15px] h-auto"
					>
						Brief description (required)
					</label>
					<FormCustomTextarea
						value={textDescription}
						onChange={(e) => setTextDescription(e.target.value)}
						onFocus={textareaFocus}
					/>
				</div>
				{/* <div className="w-full">
					<div className="flex items-center gap-[15px] mb-[13px]">
						<label htmlFor="upload" className="block text-[#FAF8F8] text-[13px] font-medium leading-5 h-auto">
							Upload files
						</label>
						<Image
							src="/icon/BiPlusCircle.png"
							alt="upload file"
							cursor="pointer"
							width="24px"
							height="24px"
							onClick={handleUploadClick}
						/>
					</div>
					<input type="file" id="upload" ref={fileInputRef} style={{ display: 'none' }} onChange={handleFileChange} />
					<p className="text-xs text-gray-400">
						Please attach proof of purchase and other files that will help us with solving your problem.
					</p>
					<div className="uploaded-files">
						{Array.isArray(uploadedFiles) &&
							uploadedFiles.length > 0 &&
							uploadedFiles.map((file) => (
								<div key={file.id} className="file-item flex items-center justify-between gap-[15px] mt-[15px] w-full">
									<p className="file-name text-[rgba(250,248,248,0.85)] text-[13px] font-normal flex-1 overflow-hidden truncate whitespace-nowrap w-1/2">
										{file.file_name}
									</p>
									<Image
										src="/icon/trash.png"
										alt="trash"
										cursor="pointer"
										width="32px"
										height="32px"
										className="shrink-0"
										onClick={() => handleRemoveFile(file.id)}
									/>
								</div>
							))}
					</div>
				</div> */}
				<div className="fixed bottom-0 right-[30px] left-[30px] pt-3 border-t border-t-[rgba(255,255,255,0.10)] mt-auto">
					<Button
						type="submit"
						isDisabled={submitCheck}
						backgroundColor="#805AD5"
						borderRadius="32px"
						color="rgb(250,248,248)"
						fontSize="13px"
						lineHeight="20px"
						padding="8px 20px"
						mb="17.99px"
						_hover={{ backgroundColor: '#B39CE6' }}
						_active={{ backgroundColor: '#4D3680', color: 'rgba(0,0,0,0.85)' }}
						_disabled={{ backgroundColor: 'rgba(128,90,213,0.3)', color: 'rgba(255,248,248,0.3)' }}
					>
						Submit
					</Button>
				</div>
			</form>

			<Modal isOpen={isOpen} onClose={handleModalClose}>
				<ModalOverlay />
				<ModalContent backgroundColor="#FFF" color="#000" borderRadius="16px" padding="20px" margin="auto 30px">
					<ModalHeader fontSize="20px" fontWeight="semibold" lineHeight="48px" padding="0px">
						Thanks!
					</ModalHeader>
					<ModalBody padding="0px" marginTop="9px">
						<p className="text-[13px] font-normal leading-5">
							Thanks for getting in touch with us.
							<br /> We will get back to you shortly.
						</p>
					</ModalBody>

					<ModalCloseButton position="absolute" top="20px" right="20px" size="44px" onClick={handleModalClose}>
						<CircularProgress size="44px" thickness="3px" color="#805AD5" value={progress}>
							<CircularProgressLabel>
								<X size={18} width="100%" />
							</CircularProgressLabel>
						</CircularProgress>
					</ModalCloseButton>
				</ModalContent>
			</Modal>
		</div>
	)
}

export default ContactForm
