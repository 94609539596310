import { Button, Text } from '@chakra-ui/react'

const DocChip = ({ name, handleDocChipClick }) => (
	<Button
		display="flex"
		alignItems="center"
		borderRadius="2px"
		backgroundColor="rgba(255,255,255,0.07)"
		height="16px"
		minWidth="16px"
		padding="0"
		onClick={(e) => {
			e.stopPropagation()
			handleDocChipClick()
		}}
		cursor="pointer"
		_hover={{ backgroundColor: 'rgba(170, 133, 217, 0.4)' }} // Replace with your color for hover state
		_active={{ backgroundColor: 'rgba(170, 133, 217, 0.1)' }} // Replace with your color for active state
	>
		<Text color="#fff" fontSize="12px" fontStyle="normal" fontWeight={400} lineHeight="normal">
			{name.length > 20 ? `${name.substring(0, 20)}...` : name}
		</Text>
	</Button>
)

export default DocChip
