import { createContext } from 'react'

const queryString = window.location.search
const urlParams = new URLSearchParams(queryString)
const productId = urlParams.get('productId')

export const contextDefaultProperties = {
	messages: [],
	setMessages: () => { },
	setShowRes: () => { },
	showRes: { url: '', name: '' },
	showSku: false,
	writeMsg: false,
	setShowSku: () => { },
	setWriteMsg: () => { },
	productId,
	body: '',
	setShowSecondaryPanel: () => { },
	onPanelOpen: () => { },
	onPanelClose: () => { },
	setActiveCardId: () => { },
	setAttribute: () => { },
	setKeyword: () => { },
	scrollToTop: () => { },
	reset: () => { },
	setGaClientId: () => { },
	setSearchResultTitle: () => { },
	setChips: () => { },
	setChangeBodyBg: () => { },
	setShowOnboardingTT: () => { },
	setShowPdp: () => { },
	setTimeTravelEnabled: () => { },
	setShowTimeTravelTT: () => { },
	setCurrentHistoryIndex: () => { },
	setCurrentData: () => { },
	setPdpParam: () => { },
	setSrpLoading: () => { },
	setActiveTab: () => { },
	setLoading: () => { },
	// Document/PDF
	handleOpenDoc: () => { },
	handleCloseDoc: () => { },
}

export const Context = createContext(contextDefaultProperties)
