import React, { useEffect, useMemo, useRef, useState } from 'react'
// import axios from 'axios'
import { Box, ChakraProvider, Flex, GridItem, Image } from '@chakra-ui/react'
import { Worker } from '@react-pdf-viewer/core'
import { useShallow } from 'zustand/react/shallow'

import { Context, contextDefaultProperties } from './Context/Context.js'
import Documents from './components/Pdp/Documents/Documents.jsx'
import { Convo } from './components/Widget/Convo/Convo.jsx'
import { SynthFilters } from './components/Widget/SynthFilters.jsx'
import { WidgetInput } from './components/Widget/WidgetInput.jsx'
import { WidgetSearchResults } from './components/Widget/WidgetSearchResults.jsx'
import { synthSettings } from './settings/synth.settings.js'
import { widgetSettings } from './settings/widget.settings.js'
import { useStore } from './store/store.js'
import theme from './theme.js'
import { parentService } from './service/index.js'
import { X } from 'lucide-react'
import ContactForm from './components/Widget/ContactForm/ContactForm.jsx'

export const App = () => {
	const {
		replaceConvos,
		searchResults,
		synthFilters,
		widgetSession,
		setWidgetSession,
		loadHistory,
		widgetLog,
		prompt,
		setPrompt,
		handlePhoneStore,
		contactSupportForm,
		setContactSupportForm,
		storeConfiguration,
	} = useStore(
		useShallow((state) => ({
			replaceConvos: state.replaceConvos,
			searchResults: state.searchResults,
			synthFilters: state.synthFilters,
			widgetSession: state.widgetSession,
			setWidgetSession: state.setWidgetSession,
			loadHistory: state.loadHistory,
			widgetLog: state.widgetLog,
			prompt: state.prompt,
			setPrompt: state.setPrompt,
			handlePhoneStore: state.handlePhoneStore,
			contactSupportForm: state.contactSupportForm,
			setContactSupportForm: state.setContactSupportForm,
			storeConfiguration: state.storeConfiguration,
		}))
	);

	const [writeMsg, setWriteMsg] = useState(false)
	const [searchResultTitle, setSearchResultTitle] = useState(false)

	const inputRef = useRef(null)
	const submitRef = useRef(null)
	const msgTopRef = useRef(null)
	const [loading, setLoading] = useState(false)

	const [isShowSRPanel, setShowSRPanel] = useState(false)
	// Using a reference to ensure the access to the most current state of the variable
	// Direct usage of isShowSRPanel might result in a stale state, causing improper visibility of the panel
	const isShowSRPanelRef = useRef(isShowSRPanel)
	const [isShowDocPanel, setShowDocPanel] = useState(false)
	// Using ref here for the same reason as above
	const isShowDocPanelRef = useRef(isShowDocPanel)

	const [rightPanelWidth, setRightPanelWidth] = useState(widgetSettings.DOC_PANEL_DEFAULT_WIDTH)
	const [chosenDocUrl, setChosenDocUrl] = useState(null)
	const [pageIndex, setPageIndex] = useState(0)

	const mainPanelRef = useRef(null)
	const refinedSynthFilters = synthFilters.filter(([key]) => key.toLowerCase() !== synthSettings.GENERATED_SEARCH_QUERY)

	const scrollToTop = (ref) => {
		ref.current?.scrollIntoView({ behavior: 'smooth', block: 'start', })
	}

	const requestSession = () => {
		window.parent.postMessage({ action: 'cv-request-session' }, '*')
	}

	useEffect(() => {
		isShowSRPanelRef.current = isShowSRPanel;
	}, [isShowSRPanel]);


	useEffect(() => {
		isShowDocPanelRef.current = isShowDocPanel;
	}, [isShowDocPanel]);

	useEffect(() => {
		// Disable Search Result Panel on search result page
		if (widgetSession.isSearchResultsPage || widgetSession.isInstantSearchEnabled) {
			return
		}
		if (Array.isArray(searchResults) && searchResults.length > 0) {
			handleOpenSR()
		} else if (Array.isArray(searchResults) && searchResults.length === 0 && isShowSRPanel) {
			handleCloseSR()
		}
	}, [searchResults]) // not using stringify here so that it can call handleOpenSR when the result is the same, but the panel is closed

	useEffect(() => {
		if (storeConfiguration && Object.keys(storeConfiguration).length === 0 && widgetSession?.domain) {
			handlePhoneStore(widgetSession?.domain)
		}
	}, [storeConfiguration, widgetSession?.domain])

	const handleOpenSR = () => {
		if (isShowSRPanelRef.current === false) {
			handleCloseDoc() // Open one, close another
			setShowSRPanel(true)
			// window.parent.postMessage({ action: 'cv-request-auto-open' }, '*')
			window.parent.postMessage({ action: 'cv-request-resize', width: `${mainPanelRef.current.offsetWidth + widgetSettings.SR_PANEL_WIDTH}px` }, '*',)
		}
	}

	const handleCloseSR = () => {
		if (isShowSRPanelRef.current === true) {
			widgetLog('search_close')
			window.parent.postMessage(
				{ action: 'cv-request-resize', width: `${mainPanelRef.current.offsetWidth}px` },
				'*',
			)
			setTimeout(() => {
				setShowSRPanel(false)
			}, 500)
		}
	}

	const handleOpenDoc = (fileUrl, chosenIndex) => {
		if (fileUrl) {
			widgetLog('attribution_click')
			handleCloseSR() // Open one, close another 	
			setTimeout(() => {
				setPageIndex(chosenIndex)
				setChosenDocUrl(fileUrl)
				// Only check this condition after updating the page index because the doc panel might open already
				// Otherwise, it cannot update the page			
				if (isShowDocPanelRef.current === false) {
					setShowDocPanel(true)
					window.parent.postMessage({ action: 'cv-request-resize', width: `${mainPanelRef.current.offsetWidth + rightPanelWidth}px` }, '*',)
				}
			}, 500) // 0.5s timeout for the open animaiton to finish
		}
	}

	const handleCloseDoc = () => {
		if (isShowDocPanelRef.current === true) {
			window.parent.postMessage({ action: 'cv-request-resize', width: `${mainPanelRef.current.offsetWidth}px` }, '*')
			setShowDocPanel(false)
			setTimeout(() => {
				setChosenDocUrl(null) // Resetting the doc is necessary for the document component to unmount & load properly later.
				setPageIndex(0)
			}, 500) // 0.5s delay for the close animation to finish
			widgetLog('pdf_close')
		}
	}

	// ------------------------- LISTENING TO PARENT'S MESSAGES ------------------------- //
	useEffect(() => {
		// Listening to parent window
		window.addEventListener('message', async (event) => {
			if (event.data.action === 'cv-session-response') {
				if (event.data.shouldLoadHistory) { // only load history the first time this action happens
					// Retry if failed to account for BE's delay when switching page
					async function loadHistoryWithRetry(id, maxRetries = 10, interval = 1000) {
						let retries = 0;
						let history = [];

						while (retries < maxRetries) {
							try {
								history = await loadHistory(id);
								break;
							} catch (error) {
								retries++;
								if (retries >= maxRetries) {
									throw new Error(`Failed to load history after ${maxRetries} attempts: ${error.message}`);
								}
								await new Promise(resolve => setTimeout(resolve, interval));
							}
						}

						return history;
					}
					await loadHistoryWithRetry(event.data.id)
				}

				// Setting up the stream to receive session information from the parent.
				setWidgetSession(event.data)
				const rightPanelMaxWidth = Math.min(
					widgetSettings.DOC_PANEL_DEFAULT_WIDTH,
					event.data.screenSize.width - widgetSettings.MAIN_PANEL_MAX_WIDTH,
				)
				setRightPanelWidth(rightPanelMaxWidth)
				window.parent.postMessage({ action: 'cv-iframe-setup-completed' }, '*')
			}

			if (event.data.action === 'cv-open-widget') {
				if (widgetSession.hasParent !== true) {
					inputRef.current && inputRef.current.focus()
				}
			}

			if (event.data.action === 'cv-close-widget') {
				handleCloseDoc()
				handleCloseSR()
			}
		})
		// Notify the merchant store that the page is ready by requesting the session
		requestSession()
	}, [])

	const reset = () => {
		setPrompt('')
		setWriteMsg(false)
		handleCloseDoc()
		widgetLog('clear_convo')
		parentService.resetSession()

		if (Object.keys(widgetSession).length === 0) return
		// Generating the URL for reloading
		let parentUrl = new URL(widgetSession.url);
		// Remove sessionId from URL because sessionId is used to restore old session
		// and here we want to create a new session.
		parentUrl.searchParams.delete('sessionId');
		let reloadUrl = parentUrl.toString()
		if (widgetSession.isGhost === true) {
			let ghostDomain = new URL(widgetSession.ghostDomain)
			ghostDomain.searchParams.set('address', reloadUrl);
			reloadUrl = ghostDomain.toString()
		}
		window.parent.location.href = reloadUrl.toString();
	}

	const contextValue = useMemo(
		() => ({ ...contextDefaultProperties, writeMsg, setWriteMsg, searchResultTitle, setSearchResultTitle, scrollToTop, loading, setLoading, reset, handleOpenDoc, widgetLog, }),
		[scrollToTop, writeMsg, searchResultTitle, loading, reset, handleOpenDoc, widgetLog],
	)

	return (
		<Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
			<ChakraProvider theme={theme}>
				<Context.Provider value={contextValue}>
					<Box className="main__page">
						<div className="h-screen overflow-hidden grid grid-cols-[384px_auto]" name="chat-panels">
							{contactSupportForm ? (
								<Box name="mainPanel" overflow="" height="100vh" width={widgetSettings.MAIN_PANEL_MAX_WIDTH} bg="bg.black" ref={mainPanelRef} position="relative" >
									<div className="flex justify-between items-center pt-[29.5px] pb-[30.5px] px-[20px]">
										<h3 className='text-white text-lg font-semibold'>{`${storeConfiguration?.merchant_name} customer support`}</h3>
										<div className='p-[7px] cursor-pointer text-[#FFFFFF]' onClick={() => setContactSupportForm(false)}>
											<X size={'18'} />			{/*need to update the size */}
										</div>
									</div>

									<div>
										<ContactForm />
									</div>
								</Box>
							) : (
								<Box name="mainPanel" overflow="" height="100vh" width={widgetSettings.MAIN_PANEL_MAX_WIDTH} bg="bg.black" ref={mainPanelRef} position="relative" >

									<WidgetInput
										msgTopRef={msgTopRef}
										ref={inputRef}
										submitRef={submitRef}
										isEnterpriseQna={true}
										requestSession={requestSession}
										isShowSRPanel={isShowSRPanel}
										isShowDocPanel={isShowDocPanel}
									/>


									{/* Chat */}
									<Flex direction="column" name="conversationBox" minHeight="calc(100vh - 105px)" alignItems="normal" padding="0 21px" position="relative" color="white" backgroundColor="#272727" >
										<Box className="chat__wrapper" width="100%">
											<Box className="chat__main">
												<Convo
													inputRef={inputRef}
													submitRef={submitRef}
													avatar={widgetSession.appearanceConfig?.avatar}
												/>
											</Box>
										</Box>

										{/* Synthesis Panel */}
										{refinedSynthFilters?.length > 0 && (
											<SynthFilters refinedSynthFilters={refinedSynthFilters} />
										)}

										{/* Chat Footer */}
										{/* <ChatFooter domain={widgetSession.domain} /> */}
									</Flex>
								</Box>
							)}

							{/* Search Result Panel */}
							{widgetSession.isInstantSearchEnabled === false && Array.isArray(searchResults) && searchResults.length > 0 && isShowSRPanel &&
								<WidgetSearchResults widgetSession={widgetSession} searchResults={searchResults} isShowSRPanel={isShowSRPanel} handleCloseSR={handleCloseSR} />
							}

							{/* Source Docs Panel */}
							{chosenDocUrl && (
								<Box width={isShowDocPanel ? rightPanelWidth : 0} overflow="hidden" height="100vh" name="rightPanel" bg="bg.darkGrey" padding="0 18px"
									transition="width 0.5s cubic-bezier(0.820, 0.085, 0.395, 0.895)" >
									<>
										<Box display="flex" justifyContent={'flex-end'} padding={'1.1rem 1rem 0'}>
											<Image src="/icon/close-dark.png" alt="Close PDF Button" cursor="pointer" onClick={handleCloseDoc} />
										</Box>

										<Documents
											docUrl={chosenDocUrl}
											pageIndex={pageIndex}
											setPageIndex={setPageIndex}
											isEnterpriseQna={true} theme="dark" />
									</>
								</Box>
							)}
						</div>
					</Box >
				</Context.Provider >
			</ChakraProvider >
		</Worker >
	)
}


// const handleResize = (e) => {
// 	e.preventDefault()
// 	let prevX = e.clientX

// 	document.addEventListener('mousemove', resize)
// 	document.addEventListener('mouseup', stopResize)

// 	function resize(e) {
// 		const chatPanel = mainPanelRef.current
// 		if (!chatPanel) return

// 		const chatPanelNewWidth = chatPanel.offsetWidth + (e.clientX - prevX)
// 		if (chatPanelNewWidth > widgetSettings.MAIN_PANEL_MAX_WIDTH) return

// 		// Resize chat panel
// 		chatPanel.style.width = `${chatPanelNewWidth}px`
// 		// Resize iframe
//	 	window.parent.postMessage( { action: 'cv-request-resize', width: `${chatPanelNewWidth}px`, chatPanelWidth: `${chatPanelNewWidth}px` }, '*', )
// 		prevX = e.clientX
// 	}

// 	function stopResize() {
// 		document.removeEventListener('mousemove', resize)
// 		document.removeEventListener('mouseup', stopResize)
// 	}
// }