import React, { useRef } from 'react';
import { Textarea } from '@chakra-ui/react';

const FormCustomTextarea = ({ value, onChange, disabled, placeholder, onFocus }) => {
	const textareaRef = useRef(null);

	return (
		<Textarea
			ref={textareaRef}
			value={value}
			onChange={onChange}
			isDisabled={disabled}
			placeholder={placeholder}
			onFocus={onFocus}
			_placeholder={{ color: 'rgba(0,0,0,0.5)' }}
			fontSize="13px"
			lineHeight="20px"
			borderRadius="4px"
			backgroundColor="rgba(250,248,248,0.15)"
			color="rgba(255,248,248,0.5)"
			border="none"
			padding="9px"
			height="140px"
			_focus={{ backgroundColor: 'rgb(250,248,248)', border: '1.5px solid #CCC', color: 'rgba(0,0,0,0.85)' }}
		/>
	)
}

export default FormCustomTextarea;
