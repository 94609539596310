import React from 'react'

import './Typing.scss'
// Typing animation.

const Typing = () => {
	return (
		<div className="dot-flashing" data-title="dot-flashing">
			<div className="dot-flashing-1"></div>
			<div className="dot-flashing-2"></div>
			<div className="dot-flashing-3"></div>
		</div>
	)
}

export default Typing
