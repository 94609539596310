import React, { forwardRef } from 'react';
import { Input, FormControl, FormErrorMessage } from "@chakra-ui/react";

const FormCustomInput = forwardRef(({ value, onChange, isError, errorMessage, disabled, placeholder, onFocus }, ref) => {
  return (
      <FormControl isInvalid={isError}>
        <Input
          ref={ref}
          value={value}
          onChange={onChange}
          isDisabled={disabled}
          placeholder={placeholder}
          _placeholder={{ color: 'rgba(0,0,0,0.5)' }}
          onFocus={onFocus}
          fontSize="13px"
          lineHeight="20px"
          borderRadius="4px"
          backgroundColor="rgba(250,248,248,0.15)"
          color="rgba(255,248,248,0.5)"
          border='none'
          padding="10px 18px"
          _focus={{ backgroundColor: 'rgb(250,248,248)', border: '1.5px solid #CCC', color: 'rgba(0,0,0,0.85)' }}
          _invalid={{ backgroundColor: 'rgba(250, 248, 248,1)', border: 'solid 1.5px rgba(232, 115, 115,1)', color: 'rgba(0,0,0,0.85)' }}
        />
        {isError && <FormErrorMessage color="#F0A1A1" fontSize="11px" fontWeight="400" lineHeight="17px">{errorMessage}</FormErrorMessage>}
      </FormControl>
  );
});

export default FormCustomInput;
