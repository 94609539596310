import { useContext, useRef, useEffect } from 'react'

import { Context } from '@/Context/Context.js'

import { useShallow } from 'zustand/react/shallow'
import { useStore } from '../store/store.js'
import { useApiConfig } from './useApiConfig.js'
import { sentryUtils } from '../lib/index.js'

export function useEntResults() {
	const updateConvo = useStore(state => state.updateConvo)
	const [widgetSession, prompt, convos, setSearchResults, setEntLoading, setprevSrpRequestId, setSynthFilters, widgetLog] = useStore(useShallow(
		state => [state.widgetSession, state.prompt, state.convos, state.setSearchResults, state.setEntLoading, state.setprevSrpRequestId, state.setSynthFilters, state.widgetLog]
	))
	const convosRef = useRef(convos);

	const { setLoading } = useContext(Context)
	const { apiBaseUrl } = useApiConfig()

	useEffect(() => {
		convosRef.current = convos;
	}, [convos]);

	const fetchEntResults = async (payload) => {
		const endpoint = `${apiBaseUrl}/enterprise_qna_results`
		try {
			const response = await fetch(`${apiBaseUrl}/enterprise_qna_results`, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
				},
				body: payload,
			})
			const data = await response.json()
			listenForUpdates(data.request_id)
		} catch (err) {
			sentryUtils.captureApiError(err, endpoint)
			return false
		} finally {
			setEntLoading(false)
		}
		return true
	}

	const updateMessage = ({ requestId, text, sourceDocs }) => {
		setLoading(false)
		updateConvo({ requestId, text, sourceDocs })
		if (sourceDocs) {
			widgetLog && widgetLog('attribution_impression')
		}
	}

	// Subscribing to to stream
	function listenForUpdates(requestId) {
		const endpoint = `${apiBaseUrl}/enterprise_qna_response/${requestId}`
		const eventSource = new EventSource(endpoint)
		eventSource.onmessage = function (event) {
			if (event.data.includes('[STREAM DONE]')) {
				setEntLoading(false)
				eventSource.close()

			}
		}
		eventSource.addEventListener('answer', (event) => {
			setEntLoading(true)
			updateMessage({ requestId, text: event.data })
		})
		eventSource.addEventListener('source_docs', (event) => {
			setEntLoading(true)
			const rawDocs = JSON.parse(event.data)
			// Validate docs fields
			const sourceDocs = rawDocs.filter(({ file_url, page_number }) => {
				// Check if file_url is a valid URL using a regular expression
				const urlPattern = /^(http|https):\/\/([a-zA-Z0-9.-]+(\.[a-zA-Z]{2,})?(:\d+)?(\/.*)?)$/
				const isFileUrlValid = file_url && urlPattern.test(file_url)

				// Check if page_number is a string representing an integer
				const isPageNumberValid = page_number && !isNaN(page_number) && Number.isInteger(parseFloat(page_number))
				// Return true if both conditions are met
				return isFileUrlValid && isPageNumberValid
			})
			updateMessage({ requestId, sourceDocs })
		})
		eventSource.addEventListener('search_results', (event) => {
			setEntLoading(true)
			try {
				const searchResults = JSON.parse(event.data)
				updateConvo({ requestId, searchResults })
				if (Array.isArray(searchResults)) { // Allow empty SR to clear up the previous one
					setSearchResults(searchResults)
					setprevSrpRequestId(requestId)
				}
			} catch (err) {
				sentryUtils.captureApiError(err, endpoint)
			}
		})
		eventSource.addEventListener('filters', (event) => {
			setEntLoading(true)
			try {
				const data = JSON.parse(event.data)
				setSynthFilters(Object.entries(data))
				updateConvo({ requestId, synthFilters: Object.entries(data) })
			} catch (err) {
				sentryUtils.captureApiError(err, endpoint)
			}
		})
		eventSource.addEventListener('task_type', (event) => {
			if (!widgetSession.isInstantSearchEnabled || ['search', 'sku-search'].includes(event.data) === false) return
			setEntLoading(true)
			// This message is sent to main page to update the browserURL with sessionId
			window.parent.postMessage({ action: 'cv-search-session-id' }, '*')
		})
		eventSource.onerror = function (err) {
			sentryUtils.captureApiError(err, endpoint)

			eventSource.close()
		}
	}

	return { fetchEntResults }
}
