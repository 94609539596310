import { extendTheme } from '@chakra-ui/react'

const theme = extendTheme({
	colors: {
		bg: {
			black: '#272727',
			darkGrey: '#323131',
		},
		link: {
			normal: '#AA85D9',
			hover: '#CCB4EB',
			active: '#7850A0',
			disabled: '#88C9DD',
		},
	},
	breakpoints: {
		xs: '0px',
		sm: '1280px',
		md: '1366px',
		lg: '1440px',
		xl: '1536px',
		'2xl': '1920px',
	},
	styles: {
		global: {
			'::-webkit-scrollbar': {
				width: '4px',
			},
			'::-webkit-scrollbar-track': {
				borderRadius: '10px',
			},
			'::-webkit-scrollbar-thumb': {
				background: '#888',
				borderRadius: '10px',
			},
			'::-webkit-scrollbar-thumb:hover': {
				background: '#555',
			},
		},
	},
})

export default theme
