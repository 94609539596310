import * as Sentry from '@sentry/react'

export const captureApiError = (err, endpoint) => {
    Sentry.withScope((scope) => {
        scope.setFingerprint([endpoint])
        scope.setTag('api', endpoint)
        scope.setLevel('error')
        Sentry.captureException(err)
    })
    console.error(err)
}

export const captureError = (err) => {
    Sentry.withScope((scope) => {
        scope.setFingerprint(['app'])
        scope.setLevel('error')
        Sentry.captureException(err)
    })
    console.error(err)
}