import React from 'react'
import { Box, Flex, Text, HStack } from '@chakra-ui/react'
import { truncateString } from '../../lib/utils'

export const SynthFilters = ({ refinedSynthFilters }) => {

	return (
		<Box
			mt="11px"
			paddingBottom="18px"
			css={{
				// CSS to make scroll bar appear on hover
				maskImage: 'linear-gradient(to top, transparent, black), linear-gradient(to left, transparent 4px, black 4px)',
				maskSize: '100% 20000px',
				maskPosition: 'left bottom',
				WebkitMaskImage:
					'linear-gradient(to top, transparent, black), linear-gradient(to left, transparent 4px, black 4px)',
				WebkitMaskSize: '100% 20000px',
				WebkitMaskPosition: 'left bottom',
				transition: 'mask-position 0.3s, -webkit-mask-position 0.3s',
				'&:hover': {
					WebkitMaskPosition: 'left top',
				},

				// CSS to make scroll bar appear when needed
				// margin: '0px 0px 18px 0px',
				// overflow: 'auto',
			}}
		>
			<div className="text-lg leading-[48px]">You are looking for...</div>
			<div className="overflow-y-scroll max-h-[113px]">
				{refinedSynthFilters.map(([key, value], index) => {
					return <Box key={key + '-' + index} borderBottom="1px solid #323232">
						<HStack spacing={12}>
							<div className="w-[200px] h-[32px] text-[12px] flex flex-col justify-center">
								{key.toUpperCase()}
							</div>


							<div className="h-[32px] text-[13px] flex flex-col justify-center">
								{truncateString(value, 35)}
							</div>
						</HStack>
					</Box>

				})}
			</div >
		</Box >
	)
}
