import axios from 'axios'
import { create } from 'zustand'

import { useApiConfig } from '../hooks/useApiConfig.js'
import { fetchWebSearch } from './fetchWebSearch.js'
import { sentryUtils } from '../lib/index.js'

export const useStore = create((set, get) => ({
    widgetSession: {
        appearanceConfig: {
            avatar: '/icon/logo-convo-light.png',
            buttonColor: '#44337A',
            linkColor: '#B794F4'
        }
    },
    setWidgetSession: (session) => set((state) => {
        const defaultAppearanceConfig = get().widgetSession.appearanceConfig


        let { appearanceConfig = {} } = session; // Destructure to avoid directly modifying the input session object
        appearanceConfig = {
            avatar: appearanceConfig.avatar || defaultAppearanceConfig.avatar,
            buttonColor: appearanceConfig.buttonColor || defaultAppearanceConfig.buttonColor,
            linkColor: appearanceConfig.linkColor || defaultAppearanceConfig.linkColor,
        };

        // Return the new state object with updated widgetSession
        return {
            ...state,
            widgetSession: {
                ...session,
                appearanceConfig,
            },
        };
    }),

    // Ent Response
    isEntLoading: false,
    setEntLoading: (status) => set(() => ({ isEntLoading: !!status })),
    searchResults: [],
    setSearchResults: (searchResults) => {
        if (Array.isArray(searchResults) && searchResults.length > 0) {
            get().widgetLog('search_results')
            window.parent.postMessage({ action: 'cv-search-results', searchResults }, '*')
        }
        set(() => ({ searchResults }))

    },
    clearSearchResults: () => set({ searchResults: [] }),
    synthFilters: [],
    setSynthFilters: (synthFilters) => set({ synthFilters }),
    clearFilters: () => set({ synthFilters: [] }),

    // Previous Request Id
    prevSrpRequestId: null,
    setprevSrpRequestId: (prevSrpRequestId) => set({ prevSrpRequestId }),

    // Chat
    prompt: '',
    setPrompt: (prompt) => set({ prompt }),
    // Convo format: [{ userPrompt, botResponse, requestId, searchResults, sourceDocs, isWebClicked, isWebLoading }]
    convos: [],
    replaceConvos: (convos) => set({ convos }),
    appendConvos: (convo) => set(state => ({ convos: [...state.convos, convo] })),
    prependConvos: (convo) => set(state => ({ convos: [convo, ...state.convos] })),
    updateConvo: ({ requestId, text, sourceDocs, searchResults, synthFilters, isWebClicked, isWebLoading, isShowWebStopped, customerSupportForm }) => set((state) => {
        const sourceDocObject = Array.isArray(sourceDocs) && sourceDocs.length > 0 ? { sourceDocs } : {}
        const searchResultsObject = Array.isArray(searchResults) ? { searchResults } : {}
        const synthFiltersObject = Array.isArray(synthFilters) ? { synthFilters } : {}

        const newConvos = [...state.convos];
        if (newConvos.length > 0) {
            let convoIndex = newConvos.findIndex((c) => c.requestId === requestId);
            if (convoIndex === -1) { // if there is no matching requestId             
                convoIndex = 0 // attaching to the first entry              
            }
            // Update or add new chat based on `convoIndex`
            const updatedConvo = newConvos[convoIndex];

            newConvos[convoIndex] = {
                ...updatedConvo,
                ...(text ? { botResponse: (updatedConvo.botResponse || '') + text } : {}),
                // Note: verifying boolean values so it doesn't set true to false when there is no value passed in
                // verifying with typeof instead of shortcut so that it doesn't skip the false value passed in
                ...(typeof isWebClicked === 'boolean' ? { isWebClicked } : {}),
                ...(typeof isWebLoading === 'boolean' ? { isWebLoading } : {}),
                ...(typeof isShowWebStopped === 'boolean' ? { isShowWebStopped } : {}),
                ...sourceDocObject,
                ...searchResultsObject,
                ...synthFiltersObject,
                requestId,
                timestamp: updatedConvo.timestamp || new Date().toISOString(),
                session_id: state.widgetSession.id,
                page_url: state.widgetSession.url,
                product_name: state.widgetSession.productName,
                product_sku: state.widgetSession.productSku,
                customerSupportForm

            };
        } else {
            // Handling an edge case case when there is no initial convo
            newConvos.push({
                ...(text ? { botResponse: text } : {}),
                ...(typeof isWebClicked === 'boolean' ? { isWebClicked } : {}),
                ...(typeof isWebLoading === 'boolean' ? { isWebLoading } : {}),
                ...(typeof isShowWebStopped === 'boolean' ? { isShowWebStopped } : {}),
                ...sourceDocObject,
                ...searchResultsObject,
                ...synthFiltersObject,
                requestId,
                timestamp: new Date().toISOString(),
                session_id: state.widgetSession.id,
                page_url: state.widgetSession.url,
                product_name: state.widgetSession.productName,
                product_sku: state.widgetSession.productSku,
                customerSupportForm
            });
        }

        return { convos: newConvos }; // Return the updated state
    }),

    // Web search
    fetchWeb: async ({ targetRequestId }) => {
        const currentStore = get()
        await fetchWebSearch({
            targetRequestId,
            convos: currentStore.convos,
            prependConvos: currentStore.prependConvos,
            setEntLoading: currentStore.setEntLoading,
            widgetSession: currentStore.widgetSession,
            widgetLog: currentStore.widgetLog,
            updateConvo: currentStore.updateConvo
        })
    },

    isLoadingHistory: false,
    // TODO: This is not used anywhere. Remove it. 
    reloadHistory: async (sessionId) => {
        const { apiBaseUrl } = useApiConfig()

        const endpoint = `${apiBaseUrl}/enterprise_session/${sessionId}`
        const { data } = await axios.get(endpoint)
        if (Array.isArray(data) && data.length > 0) {
            const convos = data.map(d => {
                return {
                    userPrompt: d.user_message,
                    botResponse: d.bot_response,
                    requestId: d.request_id,
                    searchResults: d.search_results,
                    synthFilters: Object.entries(d.filters),
                    sourceDocs: d.source_docs,
                }
            })

            if (JSON.stringify(convos) === JSON.stringify(get().convos)) {
                return // No update if same convo
            }
            set({ convos })
        }
    },
    loadHistory: async (sessionId) => {
        const { apiBaseUrl } = useApiConfig()
        const endpoint = `${apiBaseUrl}/enterprise_session/${sessionId}`
        set({ isLoadingHistory: true })
        try {
            const { data } = await axios.get(endpoint)
            if (Array.isArray(data) && data.length > 0) {
                const convos = data.map(d => {
                    return {
                        userPrompt: d.user_message,
                        botResponse: d.bot_response,
                        requestId: d.request_id,
                        searchResults: d.search_results,
                        synthFilters: Object.entries(d.filters),
                        sourceDocs: d.source_docs,
                    }
                })
                const currentWidgetSession = get().widgetSession;

                if (Object.keys(currentWidgetSession).length != 0 && currentWidgetSession.url) {
                    let parentUrl = new URL(currentWidgetSession.url);
                    // Send the search results to the parent window only if the URL contains sessionId
                    if (parentUrl.searchParams.has('sessionId')) {
                        const latestConvo = convos[0];
                        if (latestConvo?.searchResults?.length) {
                            get().setSearchResults(latestConvo.searchResults);
                            get().setprevSrpRequestId(latestConvo.requestId);
                        }
                    }
                }
                set({ convos })
                const latestSrpConvo = data.find(d => Array.isArray(d.search_results) && d.search_results.length > 0)
                if (latestSrpConvo) {
                    set({ prevSrpRequestId: latestSrpConvo.request_id })
                }
                if (Array.isArray(data) && data[0] && data[0].filters) {
                    set({ synthFilters: Object.entries(data[0].filters) })
                }
            }

            return data
        } catch (err) {
            sentryUtils.captureApiError(err, endpoint)
        } finally {
            set({ isLoadingHistory: false })
        }
    },
    widgetLog: async (eventName) => {
        const { apiBaseUrl } = useApiConfig()
        const sessionId = get().widgetSession.id
        const pageUrl = get().widgetSession.url
        const endpoint = `${apiBaseUrl}/store-event-log/`
        try {
            if (!sessionId || !pageUrl) {
                throw new Error(`Missing log parameters: ${sessionId} - ${pageUrl}`)
            }

            await fetch(endpoint, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json', },
                body: JSON.stringify({ event_name: eventName, session_id: sessionId, page_url: pageUrl }),
            })
        } catch (err) {
            sentryUtils.captureApiError(err, endpoint)
        }
    },

    //customer support form
    storeConfiguration: {},
    handlePhoneStore: async (domain) => {
        const { apiBaseUrl } = useApiConfig()
        const endpoint = `${apiBaseUrl}/stores/configuration?domain=${domain}`
        try {
            const response = await fetch(endpoint, {
                method: 'GET',
                headers: { 'Content-Type': 'application/json', }
            })
            const data = await response.json()
            if (data?.store_phone) {
                set({ storeConfiguration: data })
            }
        } catch (err) {
            sentryUtils.captureApiError(err, endpoint)
        }
    },
    contactSupportForm: false,
    setContactSupportForm: (contactSupportForm) => set({ contactSupportForm }),
    email: '',
    setEmail: (email) => set({ email }),
    formName: '',
    setFormName: (formName) => set({ formName }),
    textDescription: '',
    setTextDescription: (textDescription) => set({ textDescription }),
    uploadedFiles: [],
    setUploadedFiles: (uploadedFiles) => set({ uploadedFiles }),
    formChatHistory: [],
    handleCustomerSupportFormPayload: () => {
        let history = [];
        const { widgetSession, convos } = get();
        convos.forEach((convo) => {
            const newHistory = {
                timestamp: convo.timestamp,
                session_id: convo.session_id,
                request_id: convo.requestId,
                page_url: convo.page_url,
                user_message: convo.userPrompt,
                bot_response: convo.botResponse,
                product_name: convo.product_name,
                product_sku: convo.product_sku,
            };
            history.push(newHistory);
        })

        set({ formChatHistory: history });
    },
    formSubmitted: false,   //to show the Thanks pop-up
    setFormSubmitted: (formSubmitted) => set({ formSubmitted }),
    handleCustomerSupportForm: async (payload) => {
        const { apiBaseUrl } = useApiConfig()
        const endpoint = `${apiBaseUrl}/customer-support-form/`
        try {
            const response = await fetch(endpoint, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json', },
                body: JSON.stringify(payload),
            })
            const data = await response.json()
            if (data.status === "success") {
                set({ formSubmitted: true })
            }
        } catch (err) {
            sentryUtils.captureApiError(err, endpoint)
        }
    },
    fetchRequestStatus: async (requestId) => {
        const { apiBaseUrl } = useApiConfig()
        const endpoint = `${apiBaseUrl}/request_id_status/${requestId}`
        try {
            const response = await fetch(endpoint, {
                method: 'GET',
            })
            const data = await response.json()
            return data.status
        } catch (err) {
            sentryUtils.captureApiError(err, endpoint)
        }

    }
}))