import { Box } from '@chakra-ui/react'
import React, { useContext, useLayoutEffect, useEffect, useRef } from 'react'
import { useShallow } from 'zustand/react/shallow'
import { Context } from '../../Context/Context.js'
import { useEntResults } from '../../hooks/useEntResults.js'
import { convertConvosToChronicle } from '../../lib/utils.js'
import { useStore } from '../../store/store.js'
import { sentryUtils } from '../../lib/index.js'
import { useApiConfig } from '../../hooks/useApiConfig.js'

const WidgetInput = React.forwardRef(({ msgTopRef, submitRef, requestSession, isShowSRPanel, isShowDocPanel }, ref) => {
	const [widgetSession, convos, prependConvos, prevSrpRequestId,
		widgetLog, prompt, setPrompt, setprevSrpRequestId, setSearchResults, fetchRequestStatus, reloadHistory] = useStore(useShallow(
			state => [state.widgetSession, state.convos, state.prependConvos, state.prevSrpRequestId,
			state.widgetLog, state.prompt, state.setPrompt, state.setprevSrpRequestId, state.setSearchResults,
			state.fetchRequestStatus, state.reloadHistory]
		))
	const convosRef = useRef(convos);
	const {
		writeMsg,
		setWriteMsg,
		scrollToTop,
		setSearchResultTitle,
		loading,
		setLoading,
		setShowTimeTravelTT,
		showTimeTravelTT,
		setCurrentHistoryIndex,
		showPdp,
		setShowPdp,
		setActiveCardId,
	} = useContext(Context)

	const { fetchEntResults } = useEntResults()

	// Focusing on the question input.
	useLayoutEffect(() => {
		if (ref) {
			ref.current.focus()
		}
	}, [ref])

	useEffect(() => {
		// Notify the merchant store that the page is ready by requesting the session
		requestSession()
	}, [])
	useEffect(() => {
		// Listening to parent window
		window.addEventListener('message', async (event) => {
			if (event.data.action === 'cv-search-request-id-init') {
				handleSearchRequestIdSubmit(event.data)
			}
		})
	}, [])

	useEffect(() => {
		convosRef.current = convos;
	}, [JSON.stringify(convos)]);

	useEffect(() => {
		const checkHistoryToSubmitPrompt = (event) => {
			if (Array.isArray(convosRef.current) && convosRef.current.length > 0) {				
				// Necessary 5-second delay to ensure the history is fully rendered
				setTimeout(() => handleSearchQuerySubmit(event.data), 3000);
			} else {
				setTimeout(() => checkHistoryToSubmitPrompt(event), 1000);
			}
		};

		window.addEventListener('message', (event) => {
			if (event.data.action === 'cv-search-query-init') {
				// checkHistoryToSubmitPrompt(event) << This is adding lot of delay;
				handleSearchQuerySubmit(event.data)
			}
		});

	}, []);

	// TODO: This is not used anywhere. Remove it. 'cv-search-session-id-init' is not used anymore.
	const handleSearchRequestIdSubmit = async ({ searchRequestId, sessionId }) => {
		const { apiBaseUrl } = useApiConfig()
		const endpoint = `${apiBaseUrl}/enterprise_session_turn/${searchRequestId}`;

		try {
			setprevSrpRequestId(searchRequestId);
			let searchResultsSet = false;
			async function fetchAndCheckBotResponse(sessionId, attempts = 0) {
				const maxAttempts = 10;
				const pollingInterval = 1000;

				// Fetch the enterprise session turn
				const response = await fetch(endpoint, { method: 'GET' });
				const data = await response.json();

				// Handle search results one time only
				if (!searchResultsSet && Array.isArray(data) && data.length > 0) {
					const searchResults = data[0].search_results;
					if (Array.isArray(searchResults)) {
						setSearchResults(searchResults);
						searchResultsSet = true; // Set flag to true after initial result is set
					}
				}

				if (!data[0]?.bot_response) {  // If bot_response does not exist, continue polling
					if (attempts < maxAttempts) { // Stop polling after maxAttempts
						setTimeout(() => fetchAndCheckBotResponse(sessionId, attempts + 1), pollingInterval);
					} else {
						await reloadHistory(sessionId);
					}
				} else {
					await reloadHistory(sessionId);
				}
			}
			await fetchAndCheckBotResponse(sessionId);
		} catch (err) {
			sentryUtils.captureApiError(err, endpoint)
		}
	}

	const handleSearchQuerySubmit = async ({ searchQuery, sessionId, productUrl }) => {
		if (!searchQuery) return
		setPrompt('')
		setWriteMsg(false)
		setLoading(true)
		scrollToTop(msgTopRef)
		widgetLog && widgetLog('submit')

		try {
			const chronicle = convertConvosToChronicle(convos, searchQuery)
			prependConvos({
				userPrompt: searchQuery
			})
			requestSession() // Update the current product URL in case the user has navigated to another page
			const data = JSON.stringify({
				user_id: sessionId,
				product_url: productUrl,
				product_name: '',
				user_input: searchQuery,
				message_history: chronicle,
				client_id: '',
				client_name: 'conversant',
				prev_srp_request_id: prevSrpRequestId,
				website_context: {
					side_panel_state: isShowSRPanel ? 'search' : isShowDocPanel ? 'pdf' : 'closed',
					website_page: 'pdp',
					website_context: isShowSRPanel ? 3 : isShowDocPanel ? 2 : 1
				}
			})
			await fetchEntResults(data)
		} catch (err) {
			setLoading(false)
			sentryUtils.captureError(err)
		}
	}

	// Backend request Handler
	const handleSubmit = async (e) => {
		e.preventDefault()
		// We clear the input.
		setPrompt('')
		setWriteMsg(false)
		// Animation loader starter
		setLoading(true)
		// Fire function that scrolls msgs to top
		scrollToTop(msgTopRef)
		setSearchResultTitle(false)

		// Check if the input is not empty and the model id exists.
		if (!prompt) {
			setLoading(false)
			e.target.inputSend.style.border = '1px solid red'
			return
		}

		widgetLog && widgetLog('submit')
		try {
			await submitPrompt(convos, prompt.trim())
		} catch (err) {
			setLoading(false)
			sentryUtils.captureError(err)
		}
	}

	const submitPrompt = async (convos, prompt) => {
		setLoading(true)
		const chronicle = convertConvosToChronicle(convos, prompt)
		prependConvos({
			userPrompt: prompt
		})
		requestSession() // Update the current product URL in case the user has navigated to another page
		const data = JSON.stringify({
			user_id: widgetSession.id,
			product_url: widgetSession.url,
			product_name: widgetSession.productName,
			user_input: prompt,
			message_history: chronicle,
			client_id: '',
			client_name: 'conversant',
			prev_srp_request_id: prevSrpRequestId,
			website_context: {
				side_panel_state: isShowSRPanel ? 'search' : isShowDocPanel ? 'pdf' : 'closed',
				website_page: 'pdp',
				website_context: isShowSRPanel ? 3 : isShowDocPanel ? 2 : 1
			}
		})
		await fetchEntResults(data)
	}

	return (
		<Box background={'rgba(255,255,255,0.07)'} padding="10px" name="widgetInput">
			<form onSubmit={handleSubmit}>
				<label>
					<input
						ref={ref}
						name="inputSend"
						autoComplete="off"
						value={prompt}
						onChange={(e) => {
							setPrompt(e.target.value)
							if (!e.target.value) {
								setWriteMsg(false)
								e.target.style.border = '1px solid red'
							} else {
								if (!writeMsg) {
									// handleCloseSR()
									widgetLog && widgetLog('typing')
								}
								setWriteMsg(true)
								e.target.style.border = 'none'
							}
						}}
						type="text"
						placeholder={'Ask AI expert'}
						style={{ padding: '11px', height: '44px', fontSize: "13px" }}
					/>
					<button
						ref={submitRef}
						onClick={handleSubmit}
						disabled={loading}
						style={{ height: '44px', width: '44px', backgroundColor: widgetSession.appearanceConfig.buttonColor }}
					>
						<svg xmlns="http://www.w3.org/2000/svg" width="44" height="44" viewBox="0 0 44 44" fill="none">
							<path fillRule="evenodd" clipRule="evenodd" d="M13.7767 22.1528C13.774 21.6619 14.1698 21.2618 14.6607 21.2592L26.956 21.1923C27.4469 21.1897 27.847 21.5854 27.8497 22.0763C27.8523 22.5672 27.4566 22.9673 26.9657 22.97L14.6703 23.0368C14.1794 23.0395 13.7794 22.6437 13.7767 22.1528Z" fill="white" />
							<path fillRule="evenodd" clipRule="evenodd" d="M21.9737 15.4383C22.3278 15.0883 22.9058 15.0846 23.2646 15.4301L30 21.9147L23.273 28.5618C22.9188 28.9118 22.3408 28.9154 21.9821 28.5699C21.6233 28.2244 21.6195 27.6606 21.9737 27.3107L27.418 21.9311L21.9821 16.6975C21.6233 16.3521 21.6195 15.7883 21.9737 15.4383Z" fill="white" />
						</svg>
					</button>
				</label>
			</form>
		</Box>
	)
})

export { WidgetInput }
