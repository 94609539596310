import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, ModalCloseButton, Button, } from '@chakra-ui/react'
import { useState } from 'react'

export const ThumbModal = ({ handleModalClose, handleSubmit, isSubmitting }) => {
    const [feedback, setFeedback] = useState('')

    return <Modal onClose={handleModalClose} isOpen={true} isCentered>
        <ModalOverlay />
        <ModalContent width="350px" marginRight={'1.5rem'} marginLeft={'1.5rem'}>
            <ModalHeader css={{ padding: '20px', width: '456px', height: '48px', fontSize: '16px' }}>
                Add optional feedback
            </ModalHeader>
            <ModalCloseButton
                css={{
                    width: '48px',
                    height: '48px',
                }}
            />
            <ModalBody
                css={{
                    fontSize: '13px',
                    padding: '0px 20px 20px 20px',
                }}
            >
                <div>
                    Help us improve our responses by sharing a bit more about why you chose this option.
                </div>
                <div className='mt-5'>
                    <textarea
                        id="feedback"
                        value={feedback}
                        onChange={(e) => setFeedback(e.target.value)}
                        rows={3}
                        cols={45}
                        placeholder="Your feedback here"
                        className="border-2 border-gray-200 p-2 w-full"
                    />
                </div>
            </ModalBody>
            <ModalFooter alignItems="center" justifyContent="left" css={{ padding: '0px 20px 20px 20px' }}>
                <Button
                    css={{
                        width: '120px',
                        height: '36px',
                        backgroundColor: isSubmitting ? '#7850A0' : '#4D0782',
                        color: '#ffffff',
                        borderRadius: '32px',
                        fontSize: '12px',
                        ':hover': {
                            backgroundColor: '#7850A0',
                        },
                        ':active': {
                            backgroundColor: '#320A5B',
                        },
                        transition: 'background-color 0.3s ease',
                    }}
                    disabled={isSubmitting}
                    onClick={() => handleSubmit(feedback)}
                >
                    Submit
                </Button>
            </ModalFooter>
        </ModalContent>
    </Modal>
}
