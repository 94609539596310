import React from 'react'
import { VStack, HStack, Box } from '@chakra-ui/react'
import { Flex } from '@chakra-ui/react'

import DocChip from './DocChip'

export default function DocChipList({ handleOpenDoc, sourceDocs }) {
	// Only display 5 items
	const formattedSourceDocs = sourceDocs.slice(0, 5).map((doc, index) => ({
		name: index + 1,
		page: parseInt(doc.page_number, 10) - 1,
		url: doc.file_url,
	}))

	return (
		<VStack spacing="9px" align="start">
			<HStack>
				<Flex wrap="wrap" maxW="332px" gap="9px" align="center">
					<Box css={{ paddingLeft: '18px', fontSize: '12px' }}>Summarized from sources</Box>
					{formattedSourceDocs.map((doc) => (
						<DocChip key={doc.name} name={doc.name} handleDocChipClick={() => handleOpenDoc(doc.url, doc.page)} />
					))}
				</Flex>
			</HStack>
		</VStack>
	)
}
