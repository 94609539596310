import React from 'react'

import { Image } from '@chakra-ui/react'

import Typing from '../../Chat/Typing/Typing.jsx'

// Three dots animation for both user and widget.
export const TypingWithIcon = ({ isBot, alt, src, withIcon = false, style, show, avatar }) => {
	if (!show) return
	return (
		<div
			className={`animation  ${isBot ? 'animation-typing-with-chat-icon' : 'animation-typing-with-you-icon'}`}
			style={style}
		>
			<div
				style={{
					width: '301px',
					display: 'flex',
					justifyContent: isBot ? 'flex-start' : 'flex-end',
					padding: '18px',
					borderRadius: isBot ? '0px 12px 12px 12px' : '12px 0px 12px 12px',
					background: isBot ? 'rgba(255, 255, 255, 0.03)' : 'rgba(255, 255, 255, 0.03)',
				}}
			>
				<Typing />
			</div>
			{withIcon ? (
				isBot ? (
					<Image alt="Avatar System" src={avatar} height="38px" width="38px"></Image>
				) : (
					<Image alt="Avatar You" src="/icon/avatar-you.png" height="38px" width="38px"></Image>
				)
			) : (
				''
			)}
		</div>
	)
}

export default TypingWithIcon
