import React, { useState, useEffect, useCallback } from 'react'
import { Image, Box, GridItem, Grid, Text, Link } from '@chakra-ui/react'
import { Viewer, Worker, OpenFil, ScrollMode, PageLayout, SpecialZoomLevel } from '@react-pdf-viewer/core'
import { ProgressBar } from '@react-pdf-viewer/core'
import '@react-pdf-viewer/core/lib/styles/index.css'
import '@react-pdf-viewer/default-layout/lib/styles/index.css'
import { fullScreenPlugin } from '@react-pdf-viewer/full-screen'

import '@react-pdf-viewer/full-screen/lib/styles/index.css'
import { getFilePlugin } from '@react-pdf-viewer/get-file'
import { pageNavigationPlugin } from '@react-pdf-viewer/page-navigation'

import { scrollModePlugin } from '@react-pdf-viewer/scroll-mode'
import { SelectionMode, selectionModePlugin } from '@react-pdf-viewer/selection-mode'
import '@react-pdf-viewer/selection-mode/lib/styles/index.css'

import { toolbarPlugin } from '@react-pdf-viewer/toolbar'

import { hoverScrollbarStyle } from '../../../Common/commonStyles'

import './Documents.css'

function Documents({ isEnterpriseQna, docUrl, pageIndex, setPageIndex, theme = 'light' }) {
	// Change theme to "dark" for dark mode
	const [pdfLoaded, setPdfLoaded] = useState(false)
	const selectionModePluginInstance = selectionModePlugin({ selectionMode: SelectionMode.Hand })

	const scrollModePluginInstance = scrollModePlugin()
	const { switchScrollMode } = scrollModePluginInstance

	const toolbarPluginInstance = toolbarPlugin()

	const { Toolbar } = toolbarPluginInstance

	const fullScreenPluginInstance = fullScreenPlugin({
		getFullScreenTarget: (pagesContainer) => {
			return pagesContainer.closest('.js-viewer-container')
		},
		//	renderExitFullScreenButton: (props) => <></>,
	})
	const { EnterFullScreen } = fullScreenPluginInstance

	const getFilePluginInstance = getFilePlugin()
	const { Download } = getFilePluginInstance

	const pageNavigationPluginInstance = pageNavigationPlugin()

	const openPage = (pageIndex) => {
		if (typeof pageIndex === 'number' && pageIndex >= 0) {
			// console.info('Opening page', pageIndex + 1)
			pageNavigationPluginInstance.jumpToPage(pageIndex)
		}
	}

	useEffect(() => {
		if (pageIndex !== null && pageIndex !== undefined) {
			openPage(pageIndex)
		}
	}, [pageIndex])

	const handleDocumentLoad = () => {
		setPdfLoaded(true)
		if (pageIndex !== null && pageIndex !== undefined) {
			openPage(pageIndex)
		}
	}

	const handlePageChange = (e) => {
		if (typeof setPageIndex === 'function' && e.currentage !== undefined) setPageIndex(e.currentage)
	}

	const CustomToolbar = useCallback(() => {
		return (
			<Toolbar>
				{(slots) => {
					const { CurrentPageLabel, CurrentScale, GoToNextPage, GoToPreviousPage, NumberOfPages, ZoomIn, ZoomOut } =
						slots

					return (
						<Grid
							style={{
								display: 'flex',
								alignItems: 'center',
								position: 'absolute',
								bottom: '80px',
								height: '44px',
								background: theme === 'dark' ? '#323131' : '#EBE8E8',
								borderRadius: '30px',
								zIndex: 9999,
								paddingLeft: '9px',
							}}
							width={{
								xs: '70%',
								sm: '80%',
								md: '70%',
								lg: '70%',
								xl: '90%',
								'2xl': '70%',
							}}
						>
							<GridItem
								style={{
									display: 'flex',
									alignItems: 'center',
									justifyContent: 'center',
									margin: '0 10px',
								}}
							>
								<GoToPreviousPage>
									{(props) => (
										<Image
											sx={{
												textDecoration: 'none',
												background: theme === 'dark' ? 'rgba(0, 0, 0, 0.05)' : 'rgba(0, 0, 0, 0.1)',
											}}
											_hover={{
												textDecoration: 'none',
												backgroundColor: theme === 'dark' ? 'rgba(0, 0, 0, 0.1)' : 'rgba(0, 0, 0, 0.05)',
											}}
											_active={{
												textDecoration: 'none',
												backgroundColor: theme === 'dark' ? 'rgba(0, 0, 0, 0.03)' : 'rgba(0, 0, 0, 0.2)',
											}}
											width="32px"
											borderRadius="32px"
											src={theme === 'dark' ? '/icon/previous-page-dark-theme.png' : '/icon/previous-page.png'}
											onClick={props.onClick}
										/>
									)}
								</GoToPreviousPage>
							</GridItem>
							<GridItem
								style={{
									display: 'flex',
									alignItems: 'center',
									justifyContent: 'center',
									color: theme === 'dark' ? '#FFF' : '#000',
									fontSize: '12px',
									fontWeight: '400',
								}}
							>
								<CurrentPageLabel />
								<span>/</span>
								<NumberOfPages />
							</GridItem>

							<GridItem
								style={{
									display: 'flex',
									alignItems: 'center',
									justifyContent: 'center',
									margin: '0 10px',
								}}
							>
								<GoToNextPage>
									{(props) => (
										<Image
											sx={{
												textDecoration: 'none',
												background: theme === 'dark' ? 'rgba(0, 0, 0, 0.05)' : 'rgba(0, 0, 0, 0.1)',
											}}
											_hover={{
												textDecoration: 'none',
												backgroundColor: theme === 'dark' ? 'rgba(0, 0, 0, 0.1)' : 'rgba(0, 0, 0, 0.05)',
											}}
											_active={{
												textDecoration: 'none',
												backgroundColor: theme === 'dark' ? 'rgba(0, 0, 0, 0.03)' : 'rgba(0, 0, 0, 0.2)',
											}}
											width="32px"
											borderRadius="32px"
											src={theme === 'dark' ? '/icon/next-page-dark-theme.png' : '/icon/next-page.png'}
											onClick={props.onClick}
										/>
									)}
								</GoToNextPage>
							</GridItem>

							<Box width="1px" h="31px" backgroundColor={theme === 'dark' ? 'rgba(0, 0, 0, 0.15)' : '#fff'}></Box>

							<GridItem
								style={{
									display: 'flex',
									alignItems: 'center',
									justifyContent: 'center',
									margin: '0 10px',
								}}
							>
								<ZoomOut>
									{(props) => (
										<Image
											sx={{
												textDecoration: 'none',
												background: theme === 'dark' ? 'rgba(0, 0, 0, 0.05)' : 'rgba(0, 0, 0, 0.1)',
											}}
											_hover={{
												textDecoration: 'none',
												backgroundColor: theme === 'dark' ? 'rgba(0, 0, 0, 0.1)' : 'rgba(0, 0, 0, 0.05)',
											}}
											_active={{
												textDecoration: 'none',
												backgroundColor: theme === 'dark' ? 'rgba(0, 0, 0, 0.03)' : 'rgba(0, 0, 0, 0.2)',
											}}
											width="32px"
											borderRadius="32px"
											src={theme === 'dark' ? '/icon/zoom-out-dark-theme.png' : '/icon/zoom-out.png'}
											onClick={props.onClick}
										/>
									)}
								</ZoomOut>
							</GridItem>

							<GridItem
								style={{
									color: theme === 'dark' ? '#FFF' : '#000',
									display: 'flex',
									alignItems: 'center',
									justifyContent: 'center',
									margin: '0 10px',
									fontSize: '12px',
								}}
							>
								<CurrentScale>{(props) => <span>{`${Math.round(props.scale * 100)}%`}</span>}</CurrentScale>
							</GridItem>

							<GridItem
								style={{
									display: 'flex',
									alignItems: 'center',
									justifyContent: 'center',
									margin: '0 10px',
								}}
							>
								<ZoomIn>
									{(props) => (
										<Image
											sx={{
												textDecoration: 'none',
												background: theme === 'dark' ? 'rgba(0, 0, 0, 0.05)' : 'rgba(0, 0, 0, 0.1)',
											}}
											_hover={{
												textDecoration: 'none',
												backgroundColor: theme === 'dark' ? 'rgba(0, 0, 0, 0.1)' : 'rgba(0, 0, 0, 0.05)',
											}}
											_active={{
												textDecoration: 'none',
												backgroundColor: theme === 'dark' ? 'rgba(0, 0, 0, 0.03)' : 'rgba(0, 0, 0, 0.2)',
											}}
											width="32px"
											borderRadius="32px"
											src={theme === 'dark' ? '/icon/zoom-in-dark-theme.png' : '/icon/zoom-in.png'}
											onClick={props.onClick}
										/>
									)}
								</ZoomIn>
							</GridItem>

							<Box width="1px" h="31px" backgroundColor={theme === 'dark' ? 'rgba(0, 0, 0, 0.15)' : '#fff'}></Box>

							<GridItem
								style={{
									display: 'flex',
									alignItems: 'center',
									justifyContent: 'center',
									margin: '0 10px',
								}}
							>
								<Download>
									{(props) => (
										<Image
											sx={{
												textDecoration: 'none',
												background: theme === 'dark' ? 'rgba(0, 0, 0, 0.05)' : 'rgba(0, 0, 0, 0.1)',
											}}
											_hover={{
												textDecoration: 'none',
												backgroundColor: theme === 'dark' ? 'rgba(0, 0, 0, 0.1)' : 'rgba(0, 0, 0, 0.05)',
											}}
											_active={{
												textDecoration: 'none',
												backgroundColor: theme === 'dark' ? 'rgba(0, 0, 0, 0.03)' : 'rgba(0, 0, 0, 0.2)',
											}}
											width="32px"
											borderRadius="32px"
											src={theme === 'dark' ? '/icon/download-dark-theme.png' : '/icon/download.png'}
											onClick={props.onClick}
										/>
									)}
								</Download>
							</GridItem>

							<Box width="1px" h="31px" backgroundColor={theme === 'dark' ? 'rgba(0, 0, 0, 0.15)' : '#fff'}></Box>
							<GridItem
								style={{
									display: 'flex',
									alignItems: 'center',
									justifyContent: 'center',
									margin: '0 10px',
								}}
							>
								<EnterFullScreen>
									{(props) => (
										<Image
											sx={{
												textDecoration: 'none',
												background: theme === 'dark' ? 'rgba(0, 0, 0, 0.05)' : 'rgba(0, 0, 0, 0.1)',
											}}
											_hover={{
												textDecoration: 'none',
												backgroundColor: theme === 'dark' ? 'rgba(0, 0, 0, 0.1)' : 'rgba(0, 0, 0, 0.05)',
											}}
											_active={{
												textDecoration: 'none',
												backgroundColor: theme === 'dark' ? 'rgba(0, 0, 0, 0.03)' : 'rgba(0, 0, 0, 0.2)',
											}}
											width="32px"
											borderRadius="32px"
											src={theme === 'dark' ? '/icon/maximize-dark-theme.png' : '/icon/maximize.png'}
											onClick={props.onClick}
										/>
									)}
								</EnterFullScreen>
							</GridItem>
						</Grid>
					)
				}}
			</Toolbar>
		)
	}, [theme])

	useEffect(() => {
		// This will switch the scroll mode after the component is rendered and mounted
		switchScrollMode(ScrollMode.Page)
	}, [])

	return (
		<Grid
			className="rpv-core__viewer"
			display="flex"
			flexDirection="column"
			height={
				isEnterpriseQna
					? 'calc(100vh - 20px)'
					: {
						xs: 'calc(100vh - 150px)',
						sm: 'calc(100vh - 150px)',
						md: 'calc(100vh - 150px)',
						lg: 'calc(100vh - 150px)',
						xl: 'calc(100vh - 70px)',
						'2xl': 'calc(100vh - 70px)',
					}
			}
			width="full"
			position="relative"
			mt={
				isEnterpriseQna
					? '10px'
					: {
						xs: '18px',
						sm: '18px',
						md: '18px',
						lg: '18px',
						xl: '40px',
						'2xl': '40px',
					}
			}
			templateColumns={{
				xs: 'repeat(15, 14px)',
				sm: 'repeat(22, 14px)',
				md: 'repeat(24, 14px)',
				lg: 'repeat(26, 14px)',
				xl: 'repeat(19, 14px)',
				'2xl': 'repeat(27, 14px)',
			}}
			gap="18px"
			css={hoverScrollbarStyle}
		>
			<GridItem
				style={{
					flex: '1 1 auto',
					overflow: 'hidden',
					margin: isEnterpriseQna ? '0' : '0 35px',
					display: 'flex',
					borderRadius: '8px',
				}}
				colSpan={{
					xs: 15,
					sm: 22,
					md: 24,
					lg: 26,
					xl: 19,
					'2xl': 27,
				}}
			>
				<Viewer
					fileUrl={docUrl}
					plugins={[
						toolbarPluginInstance,
						getFilePluginInstance,
						fullScreenPluginInstance,
						scrollModePluginInstance,
						pageNavigationPluginInstance,
						selectionModePluginInstance,
					]}
					defaultScale={SpecialZoomLevel.PageWidth}
					renderLoader={(percentages) => (
						<div style={{ width: '240px' }}>
							<ProgressBar progress={Math.round(percentages)} />
						</div>
					)}
					onDocumentLoad={handleDocumentLoad}
					onPageChange={handlePageChange}
					style={{ flex: 1, height: '100%' }}
				/>
			</GridItem>
			<GridItem
				style={{
					alignItems: 'center',
					display: 'flex',
					justifyContent: 'center',
					padding: '4px',
					height: '46px',
					margin: '18px 0px',
				}}
			>
				{pdfLoaded && <CustomToolbar />}
			</GridItem>
		</Grid>
	)
}
export default React.memo(Documents)
