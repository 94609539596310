import axios from 'axios'
import { useApiConfig } from '../hooks/useApiConfig.js'
import { sentryUtils } from '../lib/index.js'
import { convertConvosToChronicle } from '../lib/utils.js'

export const fetchWebSearch = async ({ targetRequestId, convos, prependConvos, widgetSession, widgetLog, updateConvo }) => {
    const { apiBaseUrl } = useApiConfig()
    const endpoint = `${apiBaseUrl}/web_search_results`

    try {
        updateConvo({ requestId: targetRequestId, isWebLoading: true })
        widgetLog('web_search')
        const { data } = await axios.post(endpoint, {
            user_id: widgetSession.id,
            product_url: widgetSession.url,
            product_name: widgetSession.productName,
            client_id: '',
            client_name: 'widget',
            user_input: 'search the web',
            message_history: convertConvosToChronicle(convos)
        }, {
            headers: {
                'Content-Type': 'application/json',
            },
        })

        if (data.request_id) {
            prependConvos({ requestId: data.request_id, text: '' })
            listenForUpdates({ requestId: data.request_id, updateConvo, targetRequestId })
        } else {
            sentryUtils.captureApiError('No request id found', endpoint)
        }
    } catch (err) {
        sentryUtils.captureApiError(err, endpoint)
        return false
    }
    return true
}

function listenForUpdates({ requestId, updateConvo, targetRequestId }) {
    const { apiBaseUrl } = useApiConfig()
    const endpoint = `${apiBaseUrl}/web_search_response/${requestId}`

    const eventSource = new EventSource(endpoint)
    eventSource.onmessage = function (event) {
        if (event.data.includes('[STREAM DONE]')) {
            updateConvo({ requestId: targetRequestId, isWebLoading: false })
            eventSource.close()
        }
    }
    eventSource.addEventListener('answer', (event) => {
        updateConvo({ requestId: targetRequestId, isWebLoading: false })
        updateConvo({ requestId: requestId, text: event.data })

    })

    eventSource.onerror = function (err) {
        sentryUtils.captureApiError(err, endpoint)
        eventSource.close()
    }
}


