import React, { useEffect, useRef, useState } from 'react'
import { Box, Image } from '@chakra-ui/react'

export const UserPrompt = ({ prompt }) => {
    const textRef = useRef()
    const [isMultiline, setIsMultiline] = useState(false)
    const resizeObserver = useRef(null)

    useEffect(() => {
        if (prompt) {
            const detect = () => {
                const result = textRef?.current?.clientHeight > 108
                setIsMultiline(result)
            }

            resizeObserver.current = new ResizeObserver(detect)
            resizeObserver.current.observe(textRef.current)
        }
        return () => {
            if (resizeObserver.current && textRef.current) {
                resizeObserver.current.unobserve(textRef.current)
            }
        }
    }, [prompt])


    return (
        <Box display="flex" justifyContent="flex-end">
            <Box
                position="relative"
                display="flex"
                flexDirection="row"
                alignItems={isMultiline ? 'flex-start' : 'center'}
                justifyContent="flex-end"
                gap="9px"
                className="msg__my message"
            >
                <Box
                    borderRadius="12px 0px 12px 12px"
                    background="rgba(255, 255, 255, 0.03)"
                    display="flex"
                    padding="18px"
                    justifyContent="flex-end"
                    alignItems="center"
                    flex="1 0 0"
                    ref={textRef}
                    width="301px"
                >
                    {prompt}
                </Box>
            </Box>
        </Box>
    )
}
