import { Box, Image, Link } from '@chakra-ui/react'
import { X } from 'lucide-react'
import { v4 as uuidv4 } from 'uuid'
import { truncateString } from '../../lib/utils.js'
import { widgetSettings } from '../../settings/widget.settings.js'
import { memo, useEffect, useRef } from 'react'
import { useStore } from '../../store/store.js'

export const WidgetSearchResults = memo(({ widgetSession, searchResults, isShowSRPanel, handleCloseSR }) => {

    const widgetLog = useStore(state => state.widgetLog)
    const panelRef = useRef(null);

    useEffect(() => {
        // Scrolls the panel to the top when reloading
        if (panelRef.current) {
            panelRef.current.scrollTop = 0;
        }
    }, [searchResults])

    const redirectToProductLink = (productUrl) => {
        widgetLog('srp_click')
        if (widgetSession.isGhost) {
            window.parent.location.href = `${widgetSession.ghostDomain}?address=${productUrl}`
        } else {
            window.parent.location.href = `${productUrl}`
        }
    }

    return (
        <div className={`srp-panel ${isShowSRPanel ? 'srp-panel--slide-in' : 'srp-panel--slide-out'}`}>
            {Array.isArray(searchResults) && searchResults.length > 0 && (
                <Box background="#323131" color="white" height={'100vh'} width={widgetSettings.SR_PANEL_WIDTH}>
                    {/* Header */}
                    <div className="flex justify-between items-center py-[29px] px-[18px]">
                        <Box fontWeight={"bold"}>Top recommendations</Box>
                        <div className='p-[7px] cursor-pointer rounded-full bg-white bg-opacity-0 hover:bg-opacity-10 active:bg-opacity-5' onClick={handleCloseSR}>
                            <X size={'18'} />
                        </div>
                    </div>
                    {/* Search result list */}
                    <div className='srp-list' ref={panelRef}>
                        {searchResults.map((sr, index) => (
                            <Link
                                onClick={() => redirectToProductLink(sr.page_url)}
                                _hover={{ textDecoration: 'none' }} key={uuidv4()}
                                className='srp-list__items'
                                style={{ '--order': index + 1 }}>
                                <div className="item hover:bg-opacity-10 active:bg-opacity-5 transition-bg 
                                    duration-200 ease-in cursor-pointer bg-white bg-opacity-0 p-[18px]">
                                    <div className="flex justify-center bg-white rounded-lg">
                                        <Image
                                            src={sr.primary_image ? sr.primary_image : "/images/product-placeholder.png"}
                                            alt={sr.title}
                                            width="auto"
                                            height="130px"
                                        />
                                    </div>

                                    <div className="mt-[12px] text-[15px] leading-[20px]">
                                        {sr.title ? truncateString(sr.title, widgetSettings.SR_TITLE_MAX_LENGTH) : '[PRODUCT_NAME]'}
                                    </div>
                                    <div className="mt-[2px] text-[15px] leading-[20px]">
                                        <div className="opacity-75">SKU# {sr.sku}</div>
                                        {/* <div className="mt-[2px] opacity-50">${sr.price}</div> */}
                                    </div>
                                </div>
                            </Link>
                        ))}
                    </div>
                </Box>
            )
            }
        </div >
    )
}, arePropsEqual)

// Performance improvement to avoid reloading search result on every text typing event
function arePropsEqual(oldProps, newProps) {
    return (
        JSON.stringify(oldProps.widgetSession) === JSON.stringify(newProps.widgetSession) &&
        JSON.stringify(oldProps.searchResults) === JSON.stringify(newProps.searchResults) &&
        oldProps.isShowSRPanel === newProps.isShowSRPanel
    );
}